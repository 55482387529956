<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <b-card title="Dignostic Report ">
      <b-table striped hover :fields="fields" :items="items"></b-table>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      reportData: {},
      errors_back: [],
      showDismissibleAlert: false,
      delCat: true,
      sellingSerial: null,
      buyingSerial: null,
      items: [],
      fields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          key: 'selling',
          label: 'selling',
        },
        {
          key: 'buying',
          label: 'buying',
        },
        {
          key: 'identical',
          label: 'identical',
        },
      ],
    }
  },

  created() {
    this.sellingSerial = this.$route.params.selling
    this.buyingSerial = this.$route.params.buying
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get(
          'diagnostic/reports/selling/' +
            this.sellingSerial +
            '/buying/' +
            this.buyingSerial +
            '/compare'
        )
        .then((result) => {
          this.reportData = result.data.data.report

          for (let index in this.reportData) {
            this.items.push({
              name: Object.keys(this.reportData[index])[0],
              selling:
                this.reportData[index][Object.keys(this.reportData[index])[0]]
                  .selling,
              buying:
                this.reportData[index][Object.keys(this.reportData[index])[0]]
                  .buying,
              identical:
                this.reportData[index][Object.keys(this.reportData[index])[0]]
                  .identical,
            })
          }
          //console.log(this.items)
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
